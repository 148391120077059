// Relative imports.
import { Wrapper } from './style'

const Spinner = () => (
  <Wrapper className="spinner">
    <div />
    <div />
    <div />
  </Wrapper>
)

export default Spinner
